import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Section, Icon, LinkBox, Em } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { IoIosCheckboxOutline, IoIosArrowRoundForward } from "react-icons/io";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Гольф-клуб Green Horizons
			</title>
			<meta name={"description"} content={"Розташований серед пишних ландшафтів і незайманих фарватерів, гольф-клуб Green Horizons - це найкраще місце для неперевершеної гри в гольф."} />
			<meta property={"og:title"} content={"Гольф-клуб Green Horizons"} />
			<meta property={"og:description"} content={"Розташований серед пишних ландшафтів і незайманих фарватерів, гольф-клуб Green Horizons - це найкраще місце для неперевершеної гри в гольф."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6698fbc89335410018c4cb34/images/golf-icon-1975x2048-c3av3pb5.png?v=2024-07-18T13:01:11.163Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6698fbc89335410018c4cb34/images/golf-icon-1975x2048-c3av3pb5.png?v=2024-07-18T13:01:11.163Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6698fbc89335410018c4cb34/images/golf-icon-1975x2048-c3av3pb5.png?v=2024-07-18T13:01:11.163Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6698fbc89335410018c4cb34/images/golf-icon-1975x2048-c3av3pb5.png?v=2024-07-18T13:01:11.163Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6698fbc89335410018c4cb34/images/golf-icon-1975x2048-c3av3pb5.png?v=2024-07-18T13:01:11.163Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6698fbc89335410018c4cb34/images/golf-icon-1975x2048-c3av3pb5.png?v=2024-07-18T13:01:11.163Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6698fbc89335410018c4cb34/images/golf-icon-1975x2048-c3av3pb5.png?v=2024-07-18T13:01:11.163Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="text" />
			<Override slot="text1" />
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="link2" />
			<Override slot="link3" />
			<Override slot="link4" />
		</Components.Header>
		<Section md-padding="70px 0 80px 0" quarkly-title="Product-2">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-template-rows="auto"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6698fbc89335410018c4cb34/images/golf-icon-1975x2048-c3av3pb5.png?v=2024-07-18T13:01:11.163Z) 0 0/contain no-repeat scroll padding-box"
					padding="100px 50px 100px 50px"
					md-margin="0px 0px 50px 0px"
					md-padding="50px 50px 50px 50px"
				>
					<Image
						src="https://uploads.quarkly.io/6698fbc89335410018c4cb34/images/2-3.jpg?v=2024-07-18T12:57:21.775Z"
						display="block"
						max-width="100%"
						width="350px"
						srcSet="https://smartuploads.quarkly.io/6698fbc89335410018c4cb34/images/2-3.jpg?v=2024-07-18T12%3A57%3A21.775Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6698fbc89335410018c4cb34/images/2-3.jpg?v=2024-07-18T12%3A57%3A21.775Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6698fbc89335410018c4cb34/images/2-3.jpg?v=2024-07-18T12%3A57%3A21.775Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6698fbc89335410018c4cb34/images/2-3.jpg?v=2024-07-18T12%3A57%3A21.775Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6698fbc89335410018c4cb34/images/2-3.jpg?v=2024-07-18T12%3A57%3A21.775Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6698fbc89335410018c4cb34/images/2-3.jpg?v=2024-07-18T12%3A57%3A21.775Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6698fbc89335410018c4cb34/images/2-3.jpg?v=2024-07-18T12%3A57%3A21.775Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="center"
					padding="0px 40px 0px 40px"
					lg-padding="0px 0 0px 0"
				>
					<Text margin="0px 0px 40px 0px" font="normal 500 42px/1.2 --fontFamily-serifGaramond" sm-margin="0px 0px 30px 0px">
						Ласкаво просимо до гольф-клубу Green Horizons
					</Text>
					<Text margin="0px 0px 50px 0px" font="normal 300 20px/1.5 --fontFamily-sans" color="#656b70">
						Розташований серед пишних ландшафтів і незайманих фарватерів, гольф-клуб Green Horizons - це найкраще місце для неперевершеної гри в гольф. Наш клуб створений для ентузіастів гольфу всіх рівнів, пропонуючи спокійне, але в той же час складне середовище для вдосконалення вашої гри. Ми запрошуємо вас зануритися в красу нашого ретельно доглянутого поля і насолодитися зручностями, які роблять Green Horizons раєм для гольфістів.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						lg-align-self="center"
						md-align-self="auto"
						sm-flex-direction="column"
						sm-align-items="center"
						sm-align-self="center"
					/>
				</Box>
			</Box>
		</Section>
		<Section
			padding="60px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			background="--color-darkL2"
			quarkly-title="Advantages/Features-10"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
				lg-justify-content="flex-start"
			>
				<Text
					as="h1"
					margin="0px"
					font="--headline2"
					color="--light"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="left"
				>
					Про гольф-клуб Green Horizons
				</Text>
				<Text
					as="p"
					margin="20px 0 0 0"
					font="--lead"
					font-size="20px"
					font-weight="300"
					color="--lightD2"
					width="60%"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="--base"
					text-align="left"
					lg-width="100%"
					lg-max-width="720px"
				>
					Наша місія в гольф-клубі Green Horizons - забезпечити винятковий досвід гри в гольф завдяки першокласному обладнанню, бездоганному сервісу та пристрасті до гри. На нашому полі, спроектованому всесвітньо відомими архітекторами, природна краса поєднується зі стратегічною складністю, гарантуючи, що кожен раунд буде одночасно приємним і стимулюючим. Незалежно від того, чи ви досвідчений професіонал, чи новачок, наше поле пропонує різноманітні випробування, щоб відточити свої навички та насолодитися спортом.{"\n"}
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="32px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="48px"
			>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="io"
						icon={IoIosCheckboxOutline}
						size="42px"
						color="--light"
						background="--color-red"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--lead" color="--light" margin="0px 0px 6px 0px">
							Дизайн поля світового класу
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Наше поле створено, щоб кидати виклик і надихати гольфістів усіх рівнів, завдяки різноманітним ландшафтам і захоплюючим краєвидам.
						</Text>
					</Box>
					<LinkBox
						flex-direction="row"
						align-items="center"
						justify-content="flex-start"
						color="--red"
						hover-opacity="0.7"
						transition="--opacityOut"
					>
						<Text margin="0px 0px 0px 0px" letter-spacing="0.3px">
							Дізнатися більше
						</Text>
						<Icon category="io" icon={IoIosArrowRoundForward} size="24px" />
					</LinkBox>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="io"
						icon={IoIosCheckboxOutline}
						size="42px"
						color="--light"
						background="--color-red"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--lead" color="--light" margin="0px 0px 6px 0px">
							Бездоганне обслуговування
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Наша команда з догляду за полем невтомно працює над підтриманням поля в ідеальному стані, забезпечуючи оптимальний ігровий досвід цілий рік.
						</Text>
					</Box>
					<LinkBox
						flex-direction="row"
						align-items="center"
						justify-content="flex-start"
						color="--red"
						hover-opacity="0.7"
						transition="--opacityOut"
					>
						<Text margin="0px 0px 0px 0px" letter-spacing="0.3px">
							Дізнатися більше
						</Text>
						<Icon category="io" icon={IoIosArrowRoundForward} size="24px" />
					</LinkBox>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="io"
						icon={IoIosCheckboxOutline}
						size="42px"
						color="--light"
						background="--color-red"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--lead" color="--light" margin="0px 0px 6px 0px">
							Комплексні зручності:
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Від нашого ресторану для гурманів до повністю обладнаного професійного магазину, ми пропонуємо все, що вам потрібно для незабутнього дня гри в гольф.
						</Text>
					</Box>
					<LinkBox
						flex-direction="row"
						align-items="center"
						justify-content="flex-start"
						color="--red"
						hover-opacity="0.7"
						transition="--opacityOut"
					>
						<Text margin="0px 0px 0px 0px" letter-spacing="0.3px">
							Дізнатися більше
						</Text>
						<Icon category="io" icon={IoIosArrowRoundForward} size="24px" />
					</LinkBox>
				</Box>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="32px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="48px"
				margin="2rem 0px 0px 0px"
			>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="io"
						icon={IoIosCheckboxOutline}
						size="42px"
						color="--light"
						background="--color-red"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--lead" color="--light" margin="0px 0px 6px 0px">
							Персоналізоване обслуговування
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Наш персонал прагне надавати винятковий сервіс від моменту вашого прибуття до моменту від'їзду, забезпечуючи безперебійний та приємний досвід.
						</Text>
					</Box>
					<LinkBox
						flex-direction="row"
						align-items="center"
						justify-content="flex-start"
						color="--red"
						hover-opacity="0.7"
						transition="--opacityOut"
					>
						<Text margin="0px 0px 0px 0px" letter-spacing="0.3px">
							Дізнатися більше
						</Text>
						<Icon category="io" icon={IoIosArrowRoundForward} size="24px" />
					</LinkBox>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="io"
						icon={IoIosCheckboxOutline}
						size="42px"
						color="--light"
						background="--color-red"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--lead" color="--light" margin="0px 0px 6px 0px">
							Спільнота та товариськість
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Приєднуйтесь до яскравої спільноти ентузіастів гольфу та беріть участь в ексклюзивних заходах, турнірах та світських зустрічах, які роблять Green Horizons більше, ніж просто гольф-клуб.
						</Text>
					</Box>
					<LinkBox
						flex-direction="row"
						align-items="center"
						justify-content="flex-start"
						color="--red"
						hover-opacity="0.7"
						transition="--opacityOut"
					>
						<Text margin="0px 0px 0px 0px" letter-spacing="0.3px">
							Дізнатися більше
						</Text>
						<Icon category="io" icon={IoIosArrowRoundForward} size="24px" />
					</LinkBox>
				</Box>
			</Box>
		</Section>
		<Section padding="120px 0 130px 0" md-padding="80px 0 80px 0" quarkly-title="Reviews-13">
			<Override slot="SectionContent" md-padding="0px 0 0px 0" />
			<Text margin="0px 0px 20px 0px" color="--darkL2" font="normal 600 46px/1.2 --fontFamily-serifGaramond" text-align="center">
				Виняткові умови та послуги
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				padding="0 0 0 0"
				border-radius="8px"
				margin="0px 0 0 0"
				display="flex"
				flex-direction="row"
				justify-content="space-between"
				lg-margin="0px 0 0 0px"
				md-flex-direction="column"
				lg-flex-direction="column"
				lg-padding="0 0 0 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					padding="0px 70px 0px 0"
					width="50%"
					display="flex"
					flex-direction="column"
					justify-content="center"
					md-width="100%"
					lg-width="100%"
					lg-margin="0px 0px 0 0px"
					lg-padding="0px 0px 0px 0"
					sm-padding="0 0px 0px 0"
					margin="0px 0 0px 0px"
				>
					<Box
						min-width="100px"
						min-height="100px"
						border-width="0 0 0 2px"
						border-style="solid"
						border-color="#333b41"
						padding="0px 0px 0px 25px"
						margin="0px 0px 40px 0px"
						lg-margin="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 0 0px" font="--lead" color="#333b41">
							<Em>
								Наш клубний будинок - це серце Green Horizons, де панує гостинна атмосфера як для членів клубу, так і для гостей. Насолоджуйтесь їжею в нашому ресторані для гурманів, розслабтеся з напоєм в барі або розслабтеся в нашій розкішній лаунж-зоні. Наш професійний магазин укомплектований найсучаснішим спорядженням та одягом, що гарантує, що у вас є все необхідне для ідеального дня на полі. Крім того, наші найсучасніші тренувальні майданчики, включаючи драйвінг-рідж і паттінг-грін, забезпечують ідеальні умови для вдосконалення вашої гри.
							</Em>
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					display="flex"
					flex-direction="column"
					align-items="center"
					justify-content="center"
					lg-width="100%"
					lg-order="-1"
					lg-margin="0px 0px 40px 0px"
					sm-margin="0px 0px 40px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/6698fbc89335410018c4cb34/images/2-5.jpg?v=2024-07-18T12:57:23.587Z"
						display="block"
						margin="0px 0 0px 0px"
						width="auto"
						height="500px"
						border-radius={0}
						border-color="#b0b7bb"
						object-fit="cover"
						sm-height="350px"
						max-width="100%"
						srcSet="https://smartuploads.quarkly.io/6698fbc89335410018c4cb34/images/2-5.jpg?v=2024-07-18T12%3A57%3A23.587Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6698fbc89335410018c4cb34/images/2-5.jpg?v=2024-07-18T12%3A57%3A23.587Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6698fbc89335410018c4cb34/images/2-5.jpg?v=2024-07-18T12%3A57%3A23.587Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6698fbc89335410018c4cb34/images/2-5.jpg?v=2024-07-18T12%3A57%3A23.587Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6698fbc89335410018c4cb34/images/2-5.jpg?v=2024-07-18T12%3A57%3A23.587Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6698fbc89335410018c4cb34/images/2-5.jpg?v=2024-07-18T12%3A57%3A23.587Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6698fbc89335410018c4cb34/images/2-5.jpg?v=2024-07-18T12%3A57%3A23.587Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Header />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"666ac2cae8900d0022f35234"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});